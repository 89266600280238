<!-- Informe de Miscelanea -->

<template>
  <div class="rep_misc" v-if="schema">
    <v-sheet :elevation="4">

      <!-- Cabecera -->
      <div class="cabecera">
        <base_Header 
          :componenteTipo="componenteTipo"
          :Entorno="Entorno.header"
          @onEvent="event_Header">
        </base_Header>
      </div>

      <!-- Contenido -->
      <div class="contenido">
        <!-- Botonera -->       
        <btracombi
          class="pt-3"
          :btra_permisos="schema.btra"
          :btra_particular="Entorno.btra"
          btra_modulo="R"
          @onEvent="event_btra_Report">
        </btracombi>

        <!-- Controles -->
        <v-row no-gutters>
          <v-col md="5">
            <v-subheader> Tipo Listado </v-subheader>

            <!-- <v-treeview
              :items="$store.state.schemas.report.rep_misc.datos.inf"
              item-key="d"
              item-text="n"
              item-children="c"
              activatable
              dense
              open-on-click
              return-object
              @update:active="change_inf">            
            </v-treeview> -->
          </v-col>

          <v-col md="7">
            <!-- <v-select
              v-bind="$select"
              v-model="schema.ctrls.ord.value"
              :label="schema.ctrls.ord.label"
              :items="ord"
              item-value="d"
              item-text="n">            
            </v-select> -->

            <v-text-field
              v-bind="$input"
              v-model="schema.ctrls.nom.value"
              :label="schema.ctrls.nom.label">            
            </v-text-field>

            <v-text-field
              v-bind="$input"
              v-model="schema.ctrls.sit.value"
              :label="schema.ctrls.sit.label">            
            </v-text-field>

            <!-- <v-select
              v-bind="$select"
              v-model="schema.ctrls.tip.value"
              :label="schema.ctrls.tip.label"
              :items="$store.state.schemas.report.rep_misc.datos.tipos"
              item-value="d"
              item-text="n">            
            </v-select> -->

            <ctrlfinder :schema="schema.ctrls.ori"> </ctrlfinder>
            <ctrlfinder :schema="schema.ctrls.cia"> </ctrlfinder>
          </v-col>
        </v-row>
      </div>
    </v-sheet>        
  </div>
</template>



<script>

  import { mixinReport } from "@/mixins/mixinReport.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base");
  const ctrlfinder = () => plugs.groute("ctrlfinder.vue", "comp");
  const btracombi= () => plugs.groute('btracombi.vue', 'comp');

  export default {
    mixins: [mixinReport],
    components: { base_Header, btracombi, ctrlfinder },
    props: {
      padre: { type:Object, default: function() { return {id:0, estado:'', record:{}}}}, 
      componenteTipo: { type:String, default:'R' },     
      Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
    },

    data() {
      return {
        schema: null,       
        ord: []
      };
    },

    computed: {
      //
      get_orden() {
        return [
          { id: 0, name: "Nombre" },
          { id: 1, name: "Tipo/Nombre" },
          { id: 2, name: "Origen/Nombre" },
          { id: 3, name: "Provincia/Nombre" },
          { id: 4, name: "Cia/Nombre" }
        ];
      }

      /* get_tipo()
              {
                  return [
                      { id:0, name:'Particular' },
                      { id:1, name:'Medico Forense' },
                      { id:2, name:'Hospital' },
                      { id:3, name:'Cementerio' },
                      { id:4, name:'Ayuntamiento' },
                      { id:5, name:'Guarderia' }
                  ]
              } */
    },

    methods: {
      ini_data() {
        this.Entorno.api = "rep_misc";
        this.Entorno.header.titulo = "Miscelanea";        
        this.Entorno.header.style += ";min-width:40rem";
      },

      /* event_capture(evt) {
        console.log("*** onEvent rep_misc. Accion: " + evt.accion + " ***");

        this.acciones_Report(evt);
      }, */

      // configuro entorno particular
      get_entorno_Report() {
        //this.set_btra();
        //this.set_btraExtra();

        this.Entorno.header.titulo = "Miscelanea";
        this.Entorno.report = "rep_misc";
        this.Entorno.header.style += ";min-width:60rem";
      }
    }
  };
</script>
